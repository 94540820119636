import { createSlice, createSelector } from '@reduxjs/toolkit'
import auth, { loggedIn, loggedOut } from '../Auth/authSlice'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    partner: null,
    manager_images: null,
    logo: null,
    currency: 'BYN',
  },
  reducers: {},
  extraReducers: {
    [loggedIn]: (state, { payload: { user } }) => {
      state.partner = user?.partner
      state.manager_images = user?.manager_images
      state.logo = user?.logo
    },
    [loggedOut]: (state) => {
      state.partner = null
      state.manager_images = null
      state.logo = null
    },
  },
})

const changeSettings = (data) => async (dispatch, getState, { api }) => {
  try {
    await api.profile.changeSettings(data)
    await dispatch(auth.updateUser())

    return true
  } catch {
    return false
  }
}

const profileChangeRequest = () => async (dispatch, getState, { api }) => {
  const result = await api.profile.changeProfileRequest()

  return result.status === 'success'
}

const getUser = createSelector(
  (state) => state.user,
  (user) => {
    const { partner } = user
    const managerImages = user.manager_images
    const { logo } = user
    return {
      partner,
      managerImages,
      logo,
    }
  },
)

const getCurrency = createSelector(
  (state) => state.user,
  (user) => {
    const { currency } = user
    return {
      currency,
    }
  },
)

// export const {} = userSlice.actions
export default {
  profileChangeRequest,
  getUser,
  getCurrency,
  changeSettings,
  reducer: userSlice.reducer,
}
