import { Tooltip } from 'react-tippy'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useResponsiveContext } from '../context/ResponsiveContext'

function CustomTooltip({
  title,
  children,
  distance,
  disabled,
  position,
  arrow,
  popperOptions,
  html,
  trigger,
  open,
  arrowSize,
  offset,
  disableTouch,
  interactive,
  showDisabled,
}) {
  const { isTouch } = useResponsiveContext()
  const [state, setState] = useState(true)

  useEffect(() => {
    if (isTouch && disableTouch) {
      setState(false)
    }
  }, [isTouch])

  return (
    <>
      {state && !disabled ? (
        <Tooltip
          title={title}
          arrowSize={arrowSize}
          arrow={arrow}
          trigger={trigger}
          distance={distance}
          position={position}
          popperOptions={popperOptions}
          html={html}
          open={open}
          offset={offset}
          interactive={interactive}
          disabled={showDisabled}
        >
          {children}
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

CustomTooltip.defaultProps = {
  title: 'Тултип',
  distance: 20,
  disabled: false,
  position: 'top',
  arrow: true,
  popperOptions: {},
  html: undefined,
  trigger: 'mouseenter',
  open: undefined,
  arrowSize: 'small',
  offset: 0,
  disableTouch: true,
  interactive: false,
  showDisabled: false,
}

CustomTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  distance: PropTypes.number,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  position: PropTypes.string,
  arrow: PropTypes.bool,
  popperOptions: PropTypes.objectOf(PropTypes.any),
  html: PropTypes.element,
  trigger: PropTypes.string,
  arrowSize: PropTypes.string,
  offset: PropTypes.number,
  disableTouch: PropTypes.bool,
  interactive: PropTypes.bool,
  showDisabled: PropTypes.bool,
}

export default CustomTooltip
